import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent from "./filterElement.component";

interface AHBMafiFilterContainerProps {
    ahbMafiFilter: boolean,
    onAHBFilterChanged: (poolMafiFilter: boolean) => void
}

const AHBMafiFilterContainerComponent = (props: AHBMafiFilterContainerProps) => {
    const {t} = useTranslation();

    function onAHBMafiFilterChanged() {
        props.onAHBFilterChanged(!props.ahbMafiFilter);
    }

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.AHB')}
        </Typography>
        <FilterElementComponent
            text={'AHB'}
            value={'ahb'}
            isSelected={props.ahbMafiFilter}
            key={'ahb'}
            onClick={onAHBMafiFilterChanged}/>
    </Box>;
}

export default AHBMafiFilterContainerComponent;
