import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Toolbar,
    Typography
} from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/Help';

import {useAppSelector} from "../../store/hooks";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {store} from "../../store/store";
import {useTheme} from "@mui/material/styles";
import {BuildInfoDialogComponent} from "@blg/blg-core";
import InfoIcon from '@mui/icons-material/Info';
import {decoupleMafi} from "../../misc/mafiHelper";
import TutorialDialogComponent from "../dialogs/tutorialDialog.component";
import NightModeToggle from "../theme/nightModeToggle";

const styles = {
    iconStyle: {
        width: 40,
        height: 40,
    },
};

enum NavigationClick {
    BACK = 1,
    HOME = 2,
    NONE = 3
}

const NavigationComponent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const username = useAppSelector(state => state.auth).username;
    const activeView = useAppSelector(state => state.global).activeView;
    const isAttached = useAppSelector(state => state.mafi).isAttached;
    const attachedMafi = useAppSelector(state => state.mafi).attachedMafi;

    const driverLatitude = useAppSelector(state => state.driver).latitude;
    const driverLongitude = useAppSelector(state => state.driver).longitude;

    const [showBuildInfo, setShowBuildInfo] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const [showLeaveDialog, setShowLeaveDialog] = useState(false);
    const [showShutdownDialog, setShowShutdownDialog] = useState(false);

    const [navigationClick, setNavigationClick] = useState<NavigationClick>(NavigationClick.NONE);

    const location = useLocation();

    useEffect(() => {
        setShowBackButton(location.pathname !== ROUTES.HOME && location.pathname !== ROUTES.TASK_SELECTION);
    }, [location]);

    function navigateHome() {
        setNavigationClick(NavigationClick.HOME);
        if (checkNavigation()) {
            navigate(ROUTES.TASK_SELECTION, {replace: true});
        }
    }

    function navigateBack() {
        setNavigationClick(NavigationClick.BACK);
        if (checkNavigation()) {
            navigate((-1));
        }
    }

    function checkNavigation(): boolean {
        if (isAttached && !location.pathname.includes('unstack')) {
            setShowLeaveDialog(true);
        }
        return !isAttached || location.pathname.includes('unstack');
    }

    function toolbarButton() {
        if (showBackButton) {
            return (
                <Box sx={{display: "flex"}}>
                    <IconButton
                        edge="start"
                        onClick={() => {
                            navigateHome();
                        }}
                    >
                        <HomeIcon sx={{color: theme.palette.white.main}} style={styles.iconStyle}/>
                    </IconButton>
                    <IconButton
                        edge="start"
                        onClick={() => {
                            navigateBack();
                        }}
                    >
                        <ArrowBackIcon sx={{color: theme.palette.white.main}} style={styles.iconStyle}/>
                    </IconButton>
                </Box>)
        } else {
            return (
                <IconButton
                    edge="start"
                    onClick={() => {
                        navigateHome();
                    }}
                >
                    <HomeIcon sx={{color: theme.palette.white.main}} style={styles.iconStyle}/>
                </IconButton>
            )
        }
    }

    return (
        <Box>
            <AppBar position="static" sx={{backgroundColor: theme.palette.primary.main}}>
                <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
                    {toolbarButton()}
                    <Typography variant="h6" sx={{flexGrow: 1, color: theme.palette.white.main, textAlign: "center"}}>
                        { /* @ts-ignore  */}
                        {t(activeView.text, activeView.params)}
                    </Typography>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Box onClick={() => setShowTutorial(true)}
                             sx={{
                                 display: "flex", flexDirection: "column", alignItems: "center",
                                 mr: 3, cursor: "pointer"
                             }}>
                            <HelpIcon sx={{color: theme.palette.white.main}}/>
                            <Typography variant={"caption"} sx={{
                                color: theme.palette.white.main,
                                mt: 0.5,
                                textAlign: "center",
                                lineHeight: "1.3"
                            }}>
                                Hilfe
                            </Typography>
                        </Box>
                        <Box onClick={() => setShowBuildInfo(true)}
                             sx={{
                                 display: "flex", flexDirection: "column", alignItems: "center",
                                 mr: 3, cursor: "pointer"
                             }}>
                            <InfoIcon sx={{color: theme.palette.white.main}}/>
                            <Typography variant={"caption"} sx={{
                                color: theme.palette.white.main,
                                mt: 0.5,
                                textAlign: "center",
                                lineHeight: "1.3"
                            }}>
                                Version {process.env.REACT_APP_VERSION}
                            </Typography>
                        </Box>
                        <NightModeToggle></NightModeToggle>
                        <Button
                            sx={{ml: 1}}
                            variant="outlined"
                            onClick={async () => {
                                if (attachedMafi && isAttached) {
                                    await decoupleMafi(attachedMafi!);
                                }
                                setTimeout(() => {
                                    store.dispatch({type: "CLEAR_STORE"});
                                    localStorage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
                                    if (window.api) {
                                        setShowShutdownDialog(true);
                                    } else {
                                        navigate(ROUTES.LOGIN);
                                    }
                                }, 350)
                            }}
                            color="inherit">
                            {t("GENERAL.LOGOUT")}
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Dialog
                open={showLeaveDialog}
            >
                <DialogContent sx={{display: "flex", flexDirection: "column"}}>
                    <Typography variant={"h5"}>
                        {t("MAFI_CONNECT_DIALOG.TITLE")}
                    </Typography>
                    <Typography sx={{mt: 3}} variant={"h6"}>
                        {t("MAFI_CONNECT_DIALOG.TEXT")}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                    <Button
                        onClick={async () => {
                            const success = await decoupleMafi(attachedMafi!);
                            setShowLeaveDialog(false);

                            if (success) {
                                if (navigationClick === NavigationClick.HOME) {
                                    navigate(ROUTES.TASK_SELECTION, {replace: true});
                                } else if (navigationClick === NavigationClick.BACK) {
                                    if (!location.pathname.includes('bypass')) {
                                        navigate(-1);
                                    }
                                }
                                setNavigationClick(NavigationClick.NONE);
                            }
                        }}
                        variant={"outlined"}>
                        ohne Location abkoppeln
                    </Button>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setShowLeaveDialog(false);
                        }}
                        sx={{color: `${theme.palette.primary.main}`}}>
                        {t("GENERAL.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showShutdownDialog}
            >
                <DialogContent sx={{display: "flex", flexDirection: "column"}}>
                    <Typography variant={"h5"}>
                        {t("SHUTDOWN_DIALOG.TITLE")}
                    </Typography>
                    <Typography sx={{mt: 3}} variant={"h6"}>
                        {t("SHUTDOWN_DIALOG.TEXT")}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                    <Button
                        onClick={async () => {
                            // Shutdown the pc on logout event
                            window.api.shutdownPC();
                        }}
                        variant={"outlined"}>
                        {t("SHUTDOWN_DIALOG.CONFIRM")}
                    </Button>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setShowShutdownDialog(false);
                            navigate(ROUTES.LOGIN);
                        }}
                        sx={{color: `${theme.palette.primary.main}`}}>
                        {t("SHUTDOWN_DIALOG.DECLINE")}
                    </Button>
                </DialogActions>
            </Dialog>
            <BuildInfoDialogComponent showDialog={showBuildInfo} username={username!}
                                      latitude={driverLatitude} longitude={driverLongitude}
                                      closeEvent={() => setShowBuildInfo(false)}/>
            <TutorialDialogComponent showDialog={showTutorial} closeEvent={() => setShowTutorial(false)}/>
        </Box>
    )
}

export default NavigationComponent;
